import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "areaToHide" ]

  show() {
    this.areaToHideTarget.classList.remove("hidden")
  }

  hide() {
    this.areaToHideTarget.classList.add("hidden")
  }

  toggle(event) {
    this.areaToHideTargets.forEach((target) => {
      if (target.parentNode != event.currentTarget.parentNode) {
        return
      }

      target.classList.contains("hidden") ? target.classList.remove("hidden")
        : target.classList.add("hidden")
    })
  }
}

/* eslint no-console:0 */

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))

const advanceCursor = () => {
  let input = document.querySelector("input[autofocus='autofocus']")
  if (input) {
    const initialValue = input.value
    input.value = ""
    input.value = initialValue
  }
}

document.addEventListener("turbolinks:load", advanceCursor)

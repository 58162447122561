import { Controller } from "stimulus"
import { csrfToken } from "../helpers/csrf_helper"

export default class extends Controller {
  static targets = [ "searchBox", "index", "results" ]

  initialize() {
    var typingTimer
  }

  search() {
    clearTimeout(this.typingTimer)
    this.typingTimer = setTimeout(() => {
      if (this.searchTerm) {
        this.fetchSearchResults()
        this.revealResults()
        return
      }
    }, 300)
  }

  fetchSearchResults() {
    var formdata = new FormData()
    formdata.append("search_term", this.searchTerm)

    fetch(this.data.get("searchUrl"), {
      body: formdata,
      credentials: "same-origin",
      headers: { "X-CSRF-Token": csrfToken() },
      method: "POST"
    })
    .then(response => response.text())
    .then(html => {
      this.resultsTarget.innerHTML = html
    })
  }

  revealResults() {
    this.resultsTarget.classList.remove('hidden')
  }

  hideResults(event) {
    if (this.searchTerm && (event.target == this.searchBoxTarget)) {
      return
    }

    this.resultsTarget.classList.add('hidden')
  }

  get searchTerm() {
    return this.searchBoxTarget.value.trim()
  }
}

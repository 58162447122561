import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "lengthMessage", "numericField", "validCharacters" ]

  validate(event) {
    let input = event.target;

    this.validateLength(input)
  }

  validateNumeric() {
    this.numericFieldTarget.value = this.ensureOnlyNumbers()
    this.numericFieldTarget.value = this.insertCommas()
  }

  validateNumericNoComma() {
    this.numericFieldTarget.value = this.ensureOnlyNumbers()
    this.numericFieldTarget.value = this.removeCommas()
  }

  validateLength(input) {
    let isValidLength = input.value.length < input.maxLength

    if (isValidLength) {
      this.ensureMessageIsHidden()
      return
    }

    this.ensureMessageIsVisibleFor(input)
  }

  validateNoBadCharacters() {
    this.validCharactersTarget.value = this.ensureNoBadCharacters()
  }

  ensureMessageIsHidden(message) {
    if (!this.hasLengthMessageTarget) return

    this.lengthMessageTarget.remove()
  }

  ensureMessageIsVisibleFor(input) {
    if (this.hasLengthMessageTarget) return

    input.closest(".input").insertAdjacentHTML(
      "beforeend",
      '<div class="info" data-target="validation.lengthMessage">'
      + '<i class="fas fa-question-circle"></i> '
      + input.dataset.lengthMessage
      + '</div>'
    )
  }

  ensureOnlyNumbers() {
    return this.numericFieldTarget.value.replace(/\D/g,'')
  }

  ensureNoBadCharacters() {
    return this.validCharactersTarget.value.replace(/[\/?.*|":><\\]/g,'')
  }

  removeCommas() {
    return this.numericFieldTarget.value.replace(/\,/g,'')
  }

  insertCommas() {
    return this.numericFieldTarget.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["links","template","teem","teem_label"]
  
  connect() {
      this.set_teem_label(null)
  }

  add_teem(event) {
    event.preventDefault()

    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_teem(event) {
    event.preventDefault()
    
    let wrapper = event.target.closest(".teem-fee-fields")
    if (wrapper.dataset.newRecord == "true" ) {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
      wrapper.querySelector("input[name*='amount']").value = "0"
    }

    this.set_teem_label(event)
  }

  select_teem(event) {
    var selected_value = event.target.value
    var list_of_selections = this.teemTargets.filter(function (x) {
         return x.closest(".teem-fee-fields").style.display != 'none'
    }).map((t)=>t.value)

    var selected_value_occurences = list_of_selections.filter((t)=>t== selected_value).length;

    let wrapper = event.target.closest(".teem-fee-fields").querySelector(".teem_error")
    if (selected_value_occurences > 1) {
      wrapper.innerHTML = "<i class='fas fa-exclamation-triangle'></i>Teems must be unique. You have already selected this teem. Remove the other entry or select a new teem."
    }   else {
      wrapper.innerHTML = ""
    }
  }

  set_teem_label(event) {
    var primary = this.teem_labelTargets.find(function (x) {
        return x.closest(".teem-fee-fields").style.display != 'none';
    });
    primary.innerHTML= 'Primary TEEM'
  }
}

import { Controller } from "stimulus"
import { csrfToken } from "../helpers/csrf_helper"

export default class extends Controller {
  static targets = [ "postalCode", "state", "city", "country" ]

  geocode() {
    let formdata = new FormData()
    formdata.append("postal_code", this.postalCode)
    formdata.append("country", this.country)

    fetch(this.data.get("url"), {
      body: formdata,
      credentials: "same-origin",
      headers: { "X-CSRF-Token": csrfToken() },
      method: "POST"
    })
    .then(response => response.json())
    .then((json) => this.updateTargets(json))
  }

  updateTargets(json) {
    if (json.country === "US")  {
      this.displayState()
      this.stateTarget.value = json.state
    } else {
      this.hideState()
    }
    this.cityTarget.value = json.city
    this.countryTarget.value = json.country
  }

  displayState() {
    this.stateTarget.closest(".input").classList.remove("hidden")
  }

  hideState() {
    this.stateTarget.value = null
    this.stateTarget.closest(".input").classList.add("hidden")
  }

  get postalCode() {
    return this.postalCodeTarget.value.trim()
  }

  get country() {
    return this.countryTarget.value.trim()
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "indicator", "items" ]

  toggle(event) {
    event.stopPropagation()
    this.itemsTarget.classList.toggle("dropdown__items_active")
    this.indicatorTarget.classList.toggle("dropdown__indicator_active")
  }

  hide(event) {
    if (event.target == this.itemsTarget) {
      return
    }
    this.itemsTarget.classList.remove("dropdown__items_active")
    this.indicatorTarget.classList.remove("dropdown__indicator_active")
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "feeProposed", "currency" ]

  update_fee_proposed(event) {
    var labelText = event.target.dataset.labelText
    this.feeProposedTarget.textContent = labelText
  }

  update_currency(event) {
    var selectedCurrency = [...event.target.children].find(this.selected)

    this.currencyTargets.forEach((target) => {
      target.textContent = selectedCurrency.dataset.currencySymbol
      console.log(target)
  })
  }

  selected(child) {
    return child.selected
  }
}

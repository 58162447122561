import { Controller } from "stimulus"
import { csrfToken } from "../helpers/csrf_helper"

export default class extends Controller {
  static targets = [ "nameBox", "match" ]

  initialize() {
    var typingTimer
  }

  search() {
    clearTimeout(this.typingTimer)
    this.typingTimer = setTimeout(() => {
      this.fetchSearchResults()
      return
    }, 300)
  }

  fetchSearchResults() {
    var search_params = new URLSearchParams({name: this.searchTerm}).toString()
    fetch(this.data.get("searchUrl") + "?" + search_params, {
      credentials: "same-origin",
      headers: { "X-CSRF-Token": csrfToken() },
      method: "GET"
    })
    .then(response => response.json())
    .then(json => {
      if (json) {
        this.matchTarget.innerHTML = "<i class='fas fa-exclamation-triangle'></i>Hmm, that looks familiar... Is this different than <a href=" + json.url + ">" + json.name + "</a>?"
        this.matchTarget.classList.remove('hidden')
      } else {
        this.matchTarget.classList.add('hidden')
      }
    })
  }

  get searchTerm() {
    return this.nameBoxTarget.value.trim()
  }
}
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "teemFee","feeProposed","errorText" ]

  feeChanged(event){
    validateFees(this.feeProposedTarget,this.teemFeeTargets,this.errorTextTarget)
  }
}

function validateFees(fee, teems, error){
  let proposed_fee = parseInt(fee.value.replace(/,/g, ''))
  let total_teem_fees = teems.reduce((a,t)=>a+(parseInt(t.value.replace(/,/g, '')) || 0), 0)
  let diff = proposed_fee - total_teem_fees

  if (proposed_fee != total_teem_fees) {
    error.innerHTML = "<i class='fas fa-exclamation-triangle'></i>Teem fees must sum to fee proposed.</a>"
    error.classList.remove('hidden')
  } else
  {
    error.classList.add('hidden')
   }
 }

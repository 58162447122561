export function csrfToken() {
  return getMetaValue("csrf-token")
}

function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  if (element) {
    return element.getAttribute("content")
  }

  return null
}

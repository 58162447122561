import { Controller } from "stimulus"
import { csrfToken } from "../helpers/csrf_helper"

export default class extends Controller {
  static targets = [ "accountSearchBox", "accountResults", "pursuitSupervisorIdField" ]

  showAllResults() {
    if (this.accountSearchBoxTarget.placeholder.value != "") {
      fetch(this.data.get("accountUrl"))
      .then(response => response.text())
      .then(html => {
        this.accountResultsTarget.innerHTML = html
        this.revealResults()
        this.scrollToQuery()
        this.setPlaceholder()
      })
    }
  }

  fetchSearchResults() {
    var formdata = new FormData()
    formdata.append("search_term", this.searchTerm)

    fetch(this.data.get("searchUrl"), {
      body: formdata,
      credentials: "same-origin",
      headers: { "X-CSRF-Token": csrfToken() },
      method: "POST"
    })
    .then(response => response.text())
    .then(html => {
      this.accountResultsTarget.innerHTML = html
      this.revealResults()
    })
  }

  revealResults() {
    this.accountResultsTarget.classList.remove('hidden')
  }

  hideResults() {
    this.accountResultsTarget.classList.add('hidden')
  }

  scrollToQuery() {
    var i, target
    var length = this.accountResultsTarget.children.length

    for (i = 0; i < length; i++) {
      target = this.accountResultsTarget.children[i]
      if (target.textContent.trim() == this.accountSearchBoxTarget.value) {
        target.parentNode.scrollTop = target.offsetTop
        return
      }
    }

    target = this.accountResultsTarget.children[0].children[0]
    target.parentNode.scrollTop = target.offsetTop
  }

  setPlaceholder() {
    var fieldValue = (this.accountSearchBoxTarget.value || this.accountSearchBoxTarget.placeholder)

    this.accountSearchBoxTarget.setAttribute("placeholder", fieldValue)
    this.accountSearchBoxTarget.value = ""
  }

  setFieldValue(event) {
    var clickInField = (event.target == this.accountSearchBoxTarget || this.accountResultsTarget.contains(event.target))
    var searchResultsHidden = this.accountResultsTarget.classList.contains("hidden")
    var emptyQuery = this.accountSearchBoxTarget.value == ""
    var invalidQuery = this.accountResultsTarget.textContent.trim() == "No results found"

    if (clickInField || searchResultsHidden) {
      return
    }

    if (emptyQuery || invalidQuery) {
      this.setValueAsPlaceholder(this.accountResultsTarget)
    } else {
      this.selectTopResult(this.accountResultsTarget)
    }
    
    this.hideResults()
  }

  setValueAsPlaceholder(target) {
    this.accountSearchBoxTarget.value = this.accountSearchBoxTarget.getAttribute("placeholder")
  }

  selectTopResult(target) {
    this.accountSearchBoxTarget.value = target.children[0].children[0].textContent.trim()
    this.pursuitSupervisorIdFieldTarget.value = target.children[0].dataset.pursuitSupervisorId
  }

  fillInPursuitSupervisor(event) {
    this.accountSearchBoxTarget.value = event.currentTarget.children[0].textContent.trim()
    this.pursuitSupervisorIdFieldTarget.value = event.currentTarget.dataset.pursuitSupervisorId
    this.hideResults()
  }

  get searchTerm() {
    return this.accountSearchBoxTarget.value.trim()
  }
}
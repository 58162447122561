import { Controller } from "stimulus"	

export default class extends Controller {	
  static targets = [ "element" ]	

  update(event) {	
    this.elementTarget.innerHTML = 	
      this.elementTarget.dataset.prefix +	
      event.target.value	
  }	
}

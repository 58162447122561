import { Controller } from "stimulus"
import { csrfToken } from "../helpers/csrf_helper"

export default class extends Controller {
  loadMore(event) {
    event.preventDefault()

    fetch(event.target.href, {
      credentials: "same-origin",
      headers: { "X-CSRF-Token": csrfToken() },
      method: event.target.dataset.verb || "GET",
    })
      .then(response => response.text())
      .then(html => {
        var list = event.target.closest(".list")
        event.target.remove()
        list.innerHTML += html
      })
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["links","template","building"]

  add_building(event) {
    event.preventDefault()

    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_building(event) {
    event.preventDefault()

    let wrapper = event.target.closest(".building-fields")
    if (wrapper.dataset.newRecord == "true" ) {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
  }

}

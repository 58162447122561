import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["duration", "startDate", "estimatedEndDate"]

  calcEndDate() {
    var month = this.startDateTarget.value - 1
    var year = this.startDateTarget.nextElementSibling.nextElementSibling.value
    var day = 1
    
    var date = new Date(year, month, day)
    var duration = parseInt(this.durationTarget.value)
    var endDateMonth = date.getMonth() + duration

    date.setMonth(endDateMonth+ 1)
    date.setDate(0)

    var monthEnd = new Intl.DateTimeFormat(
      "en-US",
      { month: "long" },
    ).format(date)
    var yearEnd = date.getFullYear()
    var dayEnd = date.getDate();

    this.estimatedEndDateTarget.innerHTML = `${monthEnd} ${dayEnd}, ${yearEnd}`
  } 
}
